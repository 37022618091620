.mat-elevation-z1,
.mat-elevation-z2,
.mat-elevation-z3,
.mat-elevation-z4,
.mat-elevation-z5,
.mat-elevation-z6,
.mat-elevation-z7,
.mat-elevation-z8,
.mat-elevation-z9 {
  box-shadow: none !important;
  border: 1px solid var(--color-card-border);
}

@for $level from 1 to 9 {
  .mat-elevation-z#{$level} {
    background: var(--mat-elevation-z#{$level});
  }
}
