body {
  &:not(.is-mobile) {
    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    ::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 0 24px var(--color-scrollbar);
      border-radius: 24px;

      border-left: 4px solid transparent;
      border-right: 1px solid transparent;
      border-top: 3px solid transparent;
      border-bottom: 2px solid transparent;
    }

    ::-webkit-scrollbar-thumb:active {
      box-shadow: inset 0 0 0 24px var(--color-scrollbar-active);
      border-radius: 8px;
    }
  }
}

@supports (-moz-appearance: none) {
  * {
    scrollbar-color: var(--color-scrollbar) transparent;
    scrollbar-width: thin;
    scrollbar-highlight-color: var(--color-scrollbar-active);
  }
}
