@use '@angular/material' as mat;
@use 'sass:map';
@use 'partials/typography' as typography;
$color-primary: #d4af37;
$color-secondary: #7e7f86;
$color-selected: rgba($color-primary, 0.0784);
$color-text-primary: rgba(#000000, 0.87);
$color-text-secondary: rgba(#000000, 0.6);
$color-foreground: white;
$color-background: #f2f1f6;

$primaryPallete: (
  50: #faf5e7,
  100: #f2e7c3,
  200: #ead79b,
  300: #e1c773,
  400: #dabb55,
  500: #d4af37,
  600: #cfa831,
  700: #c99f2a,
  800: #c39623,
  900: #b98616,
  A100: #fff8eb,
  A200: #ffe7b8,
  A400: #ffd685,
  A700: #ffcd6c,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$qmcloud-primary: mat.define-palette($primaryPallete, 500);

$accentPallete: (
  50: #f0f0f0,
  100: #d8d9db,
  200: #bfbfc3,
  300: #a5a5aa,
  400: #919298,
  500: #7e7f86,
  600: #76777e,
  700: #6b6c73,
  800: #616269,
  900: #4e4f56,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$qmcloud-accent: mat.define-palette($accentPallete, 500);

$warnPallete: (
  primary: #cf6679,
  dark: #cf6679,
  light: #cf6679,
  contrast: (
    primary: $color-text-secondary,
    dark: $color-text-secondary,
    light: $color-text-secondary,
  ),
);

$qmcloud-warn: mat.define-palette($warnPallete, primary, light, dark);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$qm-cloud-theme: mat.define-light-theme(
  (
    color: (
      primary: $qmcloud-primary,
      accent: $qmcloud-accent,
      warn: $qmcloud-warn,
    ),
    typography: typography.$config,
  )
);


$qm-cloud-theme: map.deep-merge(
  $qm-cloud-theme,
  (
    color: (
      background: (
        dialog: #fff,
        card: #fff,
        disabled-button: var(--color-inactive),
        unselected-chip: rgba(0, 0, 0, 0.12),
        background: #fff,
      ),
      foreground: (
        text: var(--color-text-primary),
        disabled: var(--color-text-secondary),
        disabled-text: var(--color-text-secondary),
        secondary-text: var(--color-text-secondary),
        disabled-button: var(--color-text-secondary),
        divider: var(--color-card-border),
      ),
    ),
  )
);

body.CAQ360-light {
  color-scheme: light;

  --color-primary: #{$color-primary};
  --color-secondary: #{$color-secondary};
  --color-inactive: #{rgba(#000, 0.08)};

  --color-input-fill: transparent;

  --color-selected: #{$color-selected};
  --color-highlight: #{rgba($color-primary, 0.15)};

  --color-text-primary: #{$color-text-primary};
  --color-text-secondary: #{$color-text-secondary};
  --color-text-menu: #{rgba(#000, 0.6)};
  --color-button-text: #000;

  --color-foreground: #{$color-foreground};
  --color-background: #{$color-background};
  --card-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
  --color-backdrop: #1e1e1e88;

  --color-attention: #cf6679;
  --color-medium: #e5be01;
  --color-good: #008351;

  
  --color-scrollbar: #9998;
  --color-scrollbar-active: #999;
  
  --mdc-icon-button-icon-color: #{$color-text-primary};
  --mat-sidenav-content-text-color: #{$color-text-primary};
  --mat-table-header-headline-color: #{$color-text-secondary};
  --mat-table-row-item-label-text-color: #{--color-text-primary};
  --mat-menu-item-label-text-color: #{$color-text-primary};
  --mat-option-label-text-color: #{$color-text-primary};
  --mat-datepicker-calendar-date-text-color: #{$color-text-primary};
  --mat-datepicker-calendar-container-text-color: #{$color-text-primary};

  --color-input-border: #0000001c;
  --color-card-border: #0000001c;
  --mat-elevation-z1: white;
  --mat-elevation-z2: white;
  --mat-elevation-z3: white;
  --mat-elevation-z4: white;
  --mat-elevation-z5: white;
  --mat-elevation-z6: white;
  --mat-elevation-z7: white;
  --mat-elevation-z8: white;
  --mat-elevation-z9: white;

  --mat-elevation-transparent-z1: rgba(255, 255, 255, 0.05);
  --mat-elevation-transparent-z2: rgba(255, 255, 255, 0.07);
  --mat-elevation-transparent-z3: rgba(255, 255, 255, 0.08);
  --mat-elevation-transparent-z4: rgba(255, 255, 255, 0.09);
  --mat-elevation-transparent-z5: rgba(255, 255, 255, 0.11);
  --mat-elevation-transparent-z6: rgba(255, 255, 255, 0.12);
  --mat-elevation-transparent-z7: rgba(255, 255, 255, 0.14);
  --mat-elevation-transparent-z8: rgba(255, 255, 255, 0.15);
  --mat-elevation-transparent-z9: rgba(255, 255, 255, 0.16);

  @include mat.all-component-colors($qm-cloud-theme);
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-button-ripple {
    display: none;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-mdc-button.mat-primary,
  .mat-mdc-outlined-button.mat-primary,
  .mat-tab-label-active {
    color: var(--color-text-primary) !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background {
    background-color: #aaa;
  }

  //mat-form-field outline color
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: var(--color-card-border) !important;
  }

  .mat-text-field .mat-expansion-panel-content,
  .mat-mdc-menu-panel {
    box-shadow: initial !important;
  }
}
