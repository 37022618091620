@use 'mixins/breakpoints' as bp;

.entry-status-text {
  top: 0px;
  float: left;
  min-height: 32px;
  display: flex;
  align-items: center;
  z-index: 11;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 8px;

  > span {
    margin-left: 4px;
  }

  > mat-icon {
    margin-bottom: 2px;
  }

  @include bp.media-breakpoint('lt-md') {
    display: none;
  }
}

.update-status-displayer {
  &.updated > span,
  &.updated > mat-icon {
    color: var(--color-good) !important;
  }

  &.updated > mat-icon::after {
    content: 'check';
  }

  &.failed > span,
  &.failed > mat-icon {
    color: var(--color-attention) !important;
  }

  &.failed > mat-icon::after {
    content: 'close';
  }

  &.updating > span,
  &.updating > mat-icon {
    color: var(--color-medium) !important;
  }

  &.updating > mat-icon::after {
    content: 'autorenew';
  }

  opacity: 1;

  &.updated {
    transition:
      opacity 2s cubic-bezier(1, 0, 0, 0),
      transform 0s linear 2s;
    opacity: 0;
    transform: scale(0);
  }

  &.initial {
    display: none;
  }
}
