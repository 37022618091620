@use 'mixins/mat-elevation' as elv;

context-menu-content {
  .dropdown.open.show.ngx-contextmenu {
    @include elv.elevation(1);
    box-shadow: var(--card-shadow);
    border-radius: var(--border-radius);
    min-width: 180px;

    .dropdown-menu.show {
      list-style-type: none;
      padding-inline-start: 0;
      margin: 0;

      > li {
        a {
          text-decoration: none;

          div {
            display: flex;
            gap: 8px;
            padding: calc(var(--app-spacing) / 2) var(--app-spacing);
            color: var(--color-text-primary);
          }
        }

        &:hover {
          a {
            > div {
              background-color: var(--color-selected);
            }
          }
        }
      }
    }
  }

  .context-menu-invisible {
    display: none;
  }

  .context-menu-visible {
    display: block;
  }
}
