@use '@angular/material' as mat;
@use 'sass:map';
@use 'partials/typography' as typography;

$color-primary: #d4af37;
$color-secondary: #ededed;
$color-selected: rgba($color-primary, 0.0784);
$color-text-primary: rgb(#fff, 0.87);
$color-text-secondary: rgb(#fff, 0.6);
$color-foreground: #1e1e1e;
$color-background: #121212;

$primaryPallete: (
  50: #faf5e7,
  100: #f2e7c3,
  200: #ead79b,
  300: #e1c773,
  400: #dabb55,
  500: #d4af37,
  600: #cfa831,
  700: #c99f2a,
  800: #c39623,
  900: #b98616,
  A100: #fff8eb,
  A200: #ffe7b8,
  A400: #ffd685,
  A700: #ffcd6c,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$qmcloud-primary: mat.define-palette($primaryPallete);

$accentPallete: (
  50: #f0f0f0,
  100: #d8d9db,
  200: #bfbfc3,
  300: #a5a5aa,
  400: #919298,
  500: #7e7f86,
  600: #76777e,
  700: #6b6c73,
  800: #616269,
  900: #4e4f56,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$qmcloud-accent: mat.define-palette($accentPallete);

$warnPallete: (
  primary: #cf6679,
  dark: #cf6679,
  light: #cf6679,
  contrast: (
    primary: $color-text-secondary,
    dark: $color-text-secondary,
    light: $color-text-secondary,
  ),
);

$qmcloud-warn: mat.define-palette($warnPallete, primary, light, dark);

$qm-cloud-theme: mat.define-dark-theme(
  (
    color: (
      primary: $qmcloud-primary,
      accent: $qmcloud-accent,
      warn: $qmcloud-warn,
    ),

    typography: typography.$config,
  )
);

$qm-cloud-theme: map.deep-merge(
  $qm-cloud-theme,
  (
    color: (
      'background': (
        dialog: #1e1e1e,
        card: #1e1e1e,
        disabled-button: rgba(255, 255, 255, 0.12),
        unselected-chip: #1e1e1e,
      ),
      'foreground': (
        text: rgb(#fff, 0.87),
        disabled: rgba(255, 255, 255, 0.38),
        disabled-button: rgba(255, 255, 255, 0.38),
        divider: #575757
      ),
    ),
  )
);



body.CAQ360-dark {
  color-scheme: dark;

  --color-primary: #{$color-primary};

  --color-secondary: #{$color-secondary};
  --color-inactive: var(--mat-elevation-z6);

  --color-selected: #{$color-selected};
  --color-highlight: #{rgba($color-primary, 0.15)};

  --color-text-primary: #{$color-text-primary};
  --color-text-secondary: #{$color-text-secondary};
  --color-text-menu: #{rgba(#fff, 0.6)};
  --color-button-text: #000;

  --mat-sidenav-content-text-color: #{$color-text-primary};
  --mat-table-header-headline-color: #{$color-text-secondary};
  --mat-table-row-item-label-text-color: #{--color-text-primary};
  --mat-menu-item-label-text-color: #{$color-text-primary};
  --mat-option-label-text-color: #{$color-text-primary};
  --mat-datepicker-calendar-date-text-color: #{$color-text-primary};
  --mat-datepicker-calendar-container-text-color: #{$color-text-primary};
  //--mdc-outlined-button-outline-color: #575757;
  
  --color-input-fill: rgba(255, 255, 255, 0.1);

  --color-foreground: #{$color-foreground};
  --color-background: #{$color-background};
  --card-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
  --color-backdrop: #1e1e1e88;

  --color-attention: #cf6679;
  --color-medium: #e5be01;
  --color-good: #008351;
  --color-scrollbar: #9998;
  --color-scrollbar-active: #999;

  --color-input-border: rgba(255, 255, 255, 0.3);
  --color-card-border: #575757;
  --mat-elevation-z1: #1d1d1d;
  --mat-elevation-z2: #222222;
  --mat-elevation-z3: #262626;
  --mat-elevation-z4: #2c2c2c;
  --mat-elevation-z5: #2e2e2e;
  --mat-elevation-z6: #323232;
  --mat-elevation-z7: #353535;
  --mat-elevation-z8: #383838;
  --mat-elevation-z9: #3b3b3b;

  --mat-elevation-transparent-z1: rgba(255, 255, 255, 0.05);
  --mat-elevation-transparent-z2: rgba(255, 255, 255, 0.07);
  --mat-elevation-transparent-z3: rgba(255, 255, 255, 0.08);
  --mat-elevation-transparent-z4: rgba(255, 255, 255, 0.09);
  --mat-elevation-transparent-z5: rgba(255, 255, 255, 0.11);
  --mat-elevation-transparent-z6: rgba(255, 255, 255, 0.12);
  --mat-elevation-transparent-z7: rgba(255, 255, 255, 0.14);
  --mat-elevation-transparent-z8: rgba(255, 255, 255, 0.15);
  --mat-elevation-transparent-z9: rgba(255, 255, 255, 0.16);
}

@include mat.all-component-themes($qm-cloud-theme);
