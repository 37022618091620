@use 'partials/placeholder';
@use 'mixins/mat-elevation' as elv;

@use 'mixins/breakpoints' as bp;

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
mat-slide-toggle,
mat-checkbox {
  margin-bottom: 32px;
}

mat-panel-title {
  mat-slide-toggle,
  mat-checkbox {
    margin-bottom: 0;
  }
}

.mat-drawer-side {
  border-left: initial !important;
}
