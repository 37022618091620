$period-border-radius: 50px;

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: transparent !important;
}

.kpi-plan-first-quarter-class,
.kpi-plan-second-quarter-class,
.kpi-plan-first-half-year-class,
.kpi-plan-third-quarter-class,
.kpi-plan-fourth-quarter-class,
.kpi-plan-second-half-year-class {
  background: rgba(var(--color-secondary), 0.6);
}

.mat-calendar-body-selected {
  background: rgba(var(--color-primary), 1);

  .mat-calendar-body-cell-content {
    color: var(--color-text-secondary) !important;
  }
}

.period-end {
  border-top-right-radius: $period-border-radius;
  border-bottom-right-radius: $period-border-radius;
  left: -1px;

  &:before,
  &:after {
    content: none !important;
  }

  .mat-calendar-body-cell-preview {
    border-top-right-radius: $period-border-radius;
    border-bottom-right-radius: $period-border-radius;
  }

  .mat-calendar-body-cell-content {
    padding-left: 2px;
  }
}

.period-start {
  border-top-left-radius: $period-border-radius;
  border-bottom-left-radius: $period-border-radius;

  &:before,
  &:after {
    content: none !important;
  }

  .mat-calendar-body-cell-preview {
    border-top-left-radius: $period-border-radius;
    border-bottom-left-radius: $period-border-radius;
  }
}

.kpi-plan-selected-week-class {
  background: rgba(var(--color-primary), 1);
  color: rgba(var(--color-primary), 1);

  .mat-calendar-body-selected,
  :not(.mat-calendar-body-selected) {
    background: rgba(var(--color-primary), 1);
    box-shadow: none !important;
  }

  mat-calendar-body-cell-content {
    background: rgba(var(--color-primary), 1);
  }
}
