@use 'mixins/mat-elevation' as elv;

%unselectableText {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

%ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
