@use 'partials/placeholder';

@use 'mixins/breakpoints' as bp;

zero-scalable-panel {
  .no-element-wrapper {
    mat-icon,
    span {
      color: var(--color-text-primary) !important;
    }
  }
}

.mat-mdc-tab-header {
  border-bottom: none !important;
  // box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
}

.module-body > .center {
  height: 100%;
  width: 100%;
}

.module-body .content-card {
  height: 100%;
  width: 100%;
}

.module-body .content-card .content {
  height: 100%;
  width: 100%;
}

span.user-mention,
.mention {
  color: var(--color-primary) !important;
  cursor: default;

  &:hover {
    text-decoration: underline;
  }

  .ql-mention-denotation-char {
    vertical-align: text-bottom;
  }
}

.custom-overlay-panel {
  height: 90vh;

  @include bp.media-breakpoint('lt-lg') {
    width: 100vw !important;
    height: 100vh !important;
  }
}
