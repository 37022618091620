@font-face {
  font-family: 'Yantramanav';
  font-weight: 400;
  font-style: normal;
  src: url('/assets/fonts/yantramanav/Yantramanav-Regular.ttf');
}

@font-face {
  font-family: 'Yantramanav';
  font-weight: 500;
  font-style: normal;
  src: url('/assets/fonts/yantramanav/Yantramanav-Medium.ttf');
}

@font-face {
  font-family: 'Yantramanav';
  font-weight: 700;
  font-style: normal;
  src: url('/assets/fonts/yantramanav/Yantramanav-Bold.ttf');
}

@font-face {
  font-family: 'Yantramanav';
  font-weight: 300;
  font-style: normal;
  src: url('/assets/fonts/yantramanav/Yantramanav-Light.ttf');
}
