@use 'mixins/mat-elevation' as elv;
@use 'mixins/theme' as tm;
@use 'partials/placeholder';

.selected-list-item {
  background: var(--color-selected) !important;

  .mat-mdc-icon {
    color: var(--color-primary);
  }
}

@include tm.for-theme('CAQ360-dark') {
  .selected-list-item {
    color: var(--color-primary);
    a,
    mat-icon,
    mat-cell {
      color: var(--color-primary);
    }
  }
}

@include tm.for-theme('SQM360-light') {
  .selected-list-item {
    color: var(--color-primary);
    a,
    mat-icon,
    mat-cell {
      color: var(--color-primary);
    }
  }
}
