@use 'mixins/mat-elevation' as elv;
@use 'partials/placeholder';
@use 'mixins/breakpoints' as bp;

quill-editor {
  border: 1px solid var(--color-input-border) !important;

  display: block;
  border-radius: var(--border-radius);
  margin: 1px 1px 1.34375em;

  position: relative;

  .ql-mention-list-container {
    background-color: var(--mat-elevation-z6);
    border: 1px solid var(--color-card-border);
    color: var(--color-text-primary);
  }

  .ql-mention-list-item.selected {
    background-color: var(--color-selected);
  }

  .quill-placeholder {
    position: absolute;
    top: -10px;
    left: 8px;
    font-size: 12px;
    padding: 0 4px;
    color: var(--color-text-secondary);
    background: var(--mat-elevation-z1);
  }

  &:hover {
    border: 2px solid var(--color-input-border) !important;
    margin: 0px 0px calc(1.34375em - 1px);
  }

  &:focus-within {
    border: 2px solid var(--color-primary) !important;
    margin: 0px 0px calc(1.34375em - 1px);

    .quill-placeholder {
      color: var(--color-primary);
    }
  }

  &[disabled] > .quill-placeholder {
    color: var(--color-inactive);
  }

  .ql-snow .ql-picker-options {
    @include elv.elevation(3);
    border-color: var(--color-card-border) !important;
  }

  .ql-editor.ql-blank::before {
    color: var(--color-text-primary);
  }

  .quill-placeholder ~ .rta-toolbar {
    margin-top: 6px;
  }

  .ql-editor {
    max-height: 600px;
    resize: none;
  }

  .ql-toolbar.ql-snow {
    min-height: 48px;
  }

  .ql-snow :is(.ql-stroke) {
    stroke: var(--color-text-primary) !important;
  }

  .ql-snow :is(.ql-picker),
  .rta-multilang {
    color: var(--color-text-primary);
  }

  .ql-blank {
    color: var(--color-inactive) !important;
  }

  .ql-container.ql-snow {
    border: none !important;
  }

  .ql-toolbar.ql-snow {
    border: none !important;

    &::after {
      counter-reset: color-input-border var(--color-input-border);
      border-bottom: 1px solid var(--color-input-border);
      content: '';
      width: 100%;
      position: absolute;
      bottom: 8px;
      left: 0;
    }
  }

  .mention {
    background: transparent;
  }

  .rta-toolbar {
    position: relative;

    &.hide-html-controls {
      > span {
        display: none !important;
      }
    }

    > button {
      padding: 0 !important;
      float: right !important;
    }
  }

  &[disabled] {
    // border: 1px solid $disabled-border-color !important;

    .ql-editor {
      color: var(--color-inactive) !important;

      &::before {
        color: var(--color-inactive) !important;
      }
    }
  }

  .ql-snow.ql-toolbar button:hover,
  .ql-snow .ql-toolbar button:hover,
  .ql-snow.ql-toolbar button:focus,
  .ql-snow .ql-toolbar button:focus,
  .ql-snow.ql-toolbar button.ql-active,
  .ql-snow .ql-toolbar button.ql-active,
  .ql-snow.ql-toolbar .ql-picker-label:hover,
  .ql-snow .ql-toolbar .ql-picker-label:hover,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active,
  .ql-snow.ql-toolbar .ql-picker-item:hover,
  .ql-snow .ql-toolbar .ql-picker-item:hover,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: var(--color-text-primary);
  }
}
body.CAQ360-dark,
body.SQM360-dark  {
  .quill-placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
}
