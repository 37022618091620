.resizer {
  width: 2px;
  height: 100%;
  position: absolute;
  right: 0;
  z-index: 1;
  cursor: col-resize;
  background-color: var(--color-card-border);

  &.resizing {
    background-color: var(--color-primary);
  }
}
