@use 'partials/placeholder';
@use 'mixins/mat-elevation' as elv;

.mat-h1,
.mat-h2,
.mat-h3,
.mat-h4,
.mat-h5,
.mat-h6,
.mat-headline-1, // TODO: @emre these mat-h1-6 are the old ones not sure if we can remove them
.mat-headline-2,
.mat-headline-3,
.mat-headline-4,
.mat-headline-5,
.mat-headline-6 {
  color: var(--color-text-primary);
}

.b-none {
  border: none !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mat-mdc-chip {
  &.mat-mdc-standard-chip {
    color: var(--color-text-primary);
    border: 1px solid var(--mat-elevation-transparent-z5);
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.*/
.mat-menu-submenu-icon {
  color: var(--color-text-primary) !important;
}

button,
input[type='email'],
input[type='tel'],
input[type='text'],
input[type='password'],
input[type='image'],
input[type='submit'],
input[type='button'],
input[type='search'],
textarea {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
}

.avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  object-fit: cover;

  img {
    object-fit: cover;
  }
}

.add-new-button {
  position: absolute !important;
  bottom: 16px;
  right: 16px;
  z-index: 3;
  max-width: 56px;
}

/* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.*/
.mat-mdc-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: var(--color-text-primary) !important;
}

.unselectable-text {
  @extend %unselectableText;
}

.mat-drawer-container {
  background: transparent !important;
}

.mat-mdc-checkbox {
  label {
    color: var(--color-text-primary);
  }
}

.expansion-panel .mat-expansion-panel .mat-expansion-panel-body .mat-mdc-form-field:first-child,
.expansion-panel .mat-expansion-panel .mat-expansion-panel-body .mat-mdc-slider:first-child {
  padding-top: 0 !important;
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper{
  padding-right: 16px !important;
}

.mat-mdc-form-field-infix {
  padding: 0 !important;
  display: flex;
  align-items: center;
}

div.mat-drawer-backdrop.mat-drawer-shown {
  background-color: var(--color-backdrop);
}

.mat-mdc-tab-label-active {
  color: var(--color-primary) !important;
  opacity: 1 !important;
}

.mat-mdc-icon {
  @extend %unselectableText;
}

button.mat-mdc-icon-button {
  $default-size: 40px;
  $custom-size: 24px;

  width: $custom-size !important;
  height: $custom-size !important;
  line-height: $custom-size !important;
  padding:0 !important;
  transform: scale(1.6666666667);

  svg,
  .mat-icon{ 
    transform: scale(.6);
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-mdc-button-touch-target {
    width: $custom-size !important;
    height: $custom-size !important;
  }

  .mat-badge-content{
    transform: scale(.6);
    margin: -16px !important;

  }
 
}

.mat-form-field {
  input.mat-mdc-input-element {
    color: var(--color-text-primary);
    caret-color: var(--color-text-primary);
  }
}

//remove mat-form-field- subscript wrapper 
.rs-wrapper{
    .mat-mdc-form-field-subscript-wrapper{
        display: none;
    }
} 

.mat-expansion-panel {
  box-shadow: none !important;
}

// Positions backdrop over whole screen
// otherwise it would be pushed by the main content left margin
div.mat-drawer-backdrop.mat-drawer-shown {
  position: fixed;
}

.mat-mdc-form-field-infix {
  .mat-mdc-input-element {
    margin-bottom: -0.25em;
  }
}

.mat-mdc-menu-panel {
  max-height: auto !important;
  box-shadow: var(--card-shadow) !important;
}

zero-step-displayer {
  .step-wrapper {
    padding: initial !important;
  }
}

.mat-mdc-menu-panel,
.mat-mdc-select-panel,
.mat-mdc-autocomplete-panel {
  background: var(--mat-elevation-z6) !important;
}

button.mat-mdc-icon-button:hover {
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/

  span.mat-mdc-button-focus-overlay {
    opacity: 0.1;
  }
}

zero-dynamic-input-list {
  .mat-expansion-panel-header {
    border-bottom: 1px solid var(--color-card-border) !important;
  }

  .mat-expansion-panel-content {
    border: initial;
  }

  .mat-content {
    button.mat-mdc-fab.mat-accent {
      background-color: var(--color-primary);
      color: var(--color-button-text);
    }
  }
}

.mat-mdc-menu-content {
  padding: 0 !important;
}

.mat-mdc-menu-panel {
  max-width: initial !important;
}

.mat-mdc-menu-panel .menu-divider {
  border-top: 1px solid var(--color-text-primary) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
.mat-mdc-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: var(--color-primary) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
:is(.mat-mdc-button, .mat-mdc-icon-button, .mat-mdc-outlined-button, .mat-mdc-unelevated-button):is(
    .cdk-keyboard-focused,
    .cdk-program-focused
  )
  .mat-button-focus-overlay {
  opacity: 0.24 !important;
}

.mat-mdc-menu-panel {
  min-height: 48px !important;
}

// .mat-select-panel {
//     max-height: initial !important;
// }
