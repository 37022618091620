@use 'mixins/mat-elevation' as elv;
@use 'mixins/breakpoints' as bp;

.mat-mdc-table {
  .mat-mdc-row,
  .mat-mdc-header-row {
    .mat-mdc-cell,
    .mat-mdc-header-cell {
      background: unset !important;
    }
  }
}
