@use '@angular/material' as mat;
@use 'fonts/yantramanav';
@use 'partials/reset';
@use 'partials/angular-material-fix';
@use 'partials/scrollbars';
@use 'partials/resizer';
@use 'partials/detail-view';
@use 'partials/datepicker';
@use 'partials/list-view';
@use 'partials/context-menu';
@use 'partials/dialog';
@use 'partials/quill-fix';
@use 'partials/table';
@use 'partials/application';
@use 'partials/mat-elevations';
@use 'partials/status-displayer';
@use 'partials/typography' as typography;

@use 'themes/caq/dark-caq';
@use 'themes/caq/light-caq';
@use 'themes/sqm/dark-sqm';
@use 'themes/sqm/light-sqm';

@import 'material-icons/iconfont/material-icons.css';

//TODO: HIER AUFGEHÖRT
@include mat.core();
@include mat.typography-hierarchy(typography.$config);

:root {
  --border-radius: 4px;
  --app-spacing: 20px;
  --mat-form-field-container-vertical-padding: 0;
  cursor: default;
}
