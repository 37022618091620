@use 'mixins/mat-elevation' as elv;
@use 'mixins/breakpoints' as bp;

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
mat-dialog-container {
  padding: 1px !important;
  color: var(--color-text-primary);

  .header {
    .search-wrapper {
      margin-left: 0px;

      @include bp.media-breakpoint('lt-lg') {
        margin-left: 0px !important;
      }
    }
  }

  .toggle-button-navbar {
    display: none !important;
  }
}

.mat-expansion-panel-content,
.mat-mdc-menu-panel {
  @include elv.elevation(5);
  box-shadow: var(--card-shadow);
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
mat-dialog-container,
mat-datepicker-content {
  background: var(--mat-elevation-z1);
  box-shadow: var(--card-shadow);
  border: none;
}

mat-calendar.add-border-spacing {
  .mat-calendar-table {
    border-spacing: 0px 4px !important;
    border-collapse: separate !important;
  }
}

zero-scheduler {
  .schedule-menu {
    padding: var(--app-spacing);
  }

  .scheduler-time-picker-wrapper {
    input {
      padding-top: 10px;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field .mat-form-field-wrapper {
      padding-bottom: var(--app-spacing) !important;
      padding-top: var(--app-spacing) !important;
    }
  }
}

.scheduler-dialog-title {
  padding: 10px !important;
}

.scheduler-dialog-close-button {
  margin-top: 10px !important;
}

.scheduler-dialog-save-row {
  display: flex;
  justify-content: flex-end;
  padding: var(--app-spacing);
}

.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}

.scheduler-time-picker {
  margin-top: 30px;
}

.timepicker {
  .timepicker__body {
    background-color: var(--color-foreground) !important;
  }

  .timepicker__actions {
    background-color: var(--color-foreground) !important;
  }

  .timepicker__header {
    background-color: var(--color-foreground) !important;
  }

  .clock-face__clock-hand {
    background-color: var(--color-foreground) !important;

    &::after {
      background-color: var(--color-foreground) !important;
    }

    &::before {
      border-color: var(--color-foreground) !important;
    }
  }

  .clock-face__number {
    > span.active {
      background-color: var(--color-foreground) !important;
    }
  }
}
